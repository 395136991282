import React from "react"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/nico/Layout"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"
import AssessmentPage from "../../components/nico/SupportProgramPage"

const support_program = () => (
  <Layout>
    <SEO title={PAGE.title.support_program} siteTitle="nico" />
    <HeadingL title={PAGE.title.support_program} target="nico" />
    <AssessmentPage />
  </Layout>
)

export default support_program;
